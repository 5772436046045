<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined :loading="requestProcessing">
        <template slot="progress">
          <v-progress-linear height="10" indeterminate></v-progress-linear>
          <p class="mt-4 text-center">Processing...</p>
        </template>
        <v-card-title v-if="!requestProcessing">
          View Message
        </v-card-title>
        <v-card-text class="mt-3" v-if="!requestProcessing">
          <!-- Start View Message section -->
          <v-container>
            <v-row>
              <v-col cols="2">
                From:
              </v-col>
              <v-col cols="10">
                {{ message.sender.fullName }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                To:
              </v-col>
              <v-col cols="10">
                {{
                  message.recipients.$values.map(el => el.fullName).join(";")
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Action Required:
              </v-col>
              <v-col cols="10">
                {{ message.isActionRequired ? "Yes" : "No" }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Subject:
              </v-col>
              <v-col cols="10">
                {{ message.subject }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Message:
              </v-col>
              <v-col cols="10">
                {{ message.body }}
              </v-col>
            </v-row>
            <v-row
              v-if="
                message.attachments && message.attachments.$values.length > 0
              "
            >
              <v-col cols="2">
                Attachments:
              </v-col>
              <v-col cols="10">
                <div
                  v-for="(item, i) in message.attachments.$values"
                  :key="'o' + i"
                >
                  <a :href="item.fileUrl">{{ item.fileName }}</a>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-btn
                class="mx-1"
                color="orange-bg white--text"
                @click="showMessageForm('reply')"
              >
                Reply
              </v-btn>
              <!-- <v-btn
                class="mx-1"
                color="orange-bg white--text"
                @click="showMessageForm('replyAll')"
              >
                Reply All
              </v-btn>
              <v-btn
                class="mx-1"
                color="orange-bg white--text"
                @click="showMessageForm('forward')"
              >
                Forward
              </v-btn> -->
            </v-row>
          </v-container>

          <v-container
            v-for="(item, i) in message.replies.$values"
            :key="'c' + i"
          >
            <hr style="background-color: #df6526; height: 1px;" />
            <v-row>
              <v-col cols="2">
                From:
              </v-col>
              <v-col cols="10" class="green--text">
                {{ item.sender.fullName }}
                {{ formatDate(item.created) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Subject:
              </v-col>
              <v-col cols="10">
                {{ item.subject }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Message:
              </v-col>
              <v-col cols="10">
                {{ item.body }}
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <hr style="background-color: #df6526; height: 1px;" />
          </v-container>
          <v-card elevation="0" outlined color="#f1f1f1" v-if="needMessageForm">
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <!-- <h6 class="font-weight-bold">{{ sendToTitle }}</h6> -->
                      <v-autocomplete
                        v-model="newMessage.sendTo"
                        :items="recipients"
                        item-text="name"
                        item-value="id"
                        dense
                        chips
                        small-chips
                        label="Send To"
                        multiple
                        solo
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="newMessage.subject"
                        label="Subject"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea
                        v-model="newMessage.body"
                        label="Message"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <div
                        v-for="attachment in attachmentsObj"
                        cols="12"
                        class="darggable-col"
                        :key="attachment.id"
                      >
                        <MediaUploader
                          class="pb-5"
                          uploadType="file"
                          :key="attachment.id"
                          :id="`multiFilePost_${attachment.id}`"
                          :index="attachment.id"
                          :accept="accept"
                          :multiple="true"
                          :extensions="extensions"
                          :sizeImage="10485760"
                          :sizeVideo="104857600"
                          :fileId.sync="attachment.fileId"
                          :file-url.sync="attachment.url"
                          :isUploading.sync="isFileUploading"
                          @onMultipleUploaded="onMultipleAddFile"
                          @onChange="onChange"
                          @onDeleteFile="onDeleteFile"
                        />
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-radio-group v-model="newMessage.isActionRequired" row>
                        <template v-slot:label>
                          <div>Action Required:</div>
                        </template>
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="0"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-btn color="orange-bg white--text" @click="sendMessage">
                    Send Message
                  </v-btn>
                  <v-btn class="mx-2" @click="cancelMessage">
                    Cancel
                  </v-btn>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
          <!-- End View Message section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  POST_DATA,
  API_CUSTOMERS,
  API_MESSAGES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs,
    MediaUploader
  },
  data: () => ({
    communityInfo: {},
    message: {
      sendTo: [],
      sendToBranch: null,
      messageSubject: "",
      messageBody: "",
      attachments: [],
      isActionRequired: "0"
    },
    menu1: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    needMessageForm: false,
    replyType: "",
    recipients: [],
    newMessage: {
      sendTo: [],
      sendToBranch: [],
      recipients: null,
      subject: "",
      body: "",
      attachments: [],
      isActionRequired: "0"
    },
    attachmentsObj: [{ id: 1, url: null }],
    isFileUploading: false,
    loadingRecipients: false,
    requestProcessing: false
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    },
    messageId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return {
      newMessage: {
        subject: {
          required
        },
        body: {
          required
        },
        recipients: {
          required,
          minLength: 1
        }
      }
    };
  },
  watch: {},
  async mounted() {},
  async created() {
    permissionsHelper.getPermissions().then(this.getPermissions);

    let self = this;
    self.loading = true;
    Promise.all([this.getComunityInfo(), this.getMessage()]).then(function() {
      self.loading = false;
    });
  },
  methods: {
    cancelMessage() {
      this.needMessageForm = false;
      this.replyType = "";

      this.newMessage = {
        sendTo: [],
        sendToBranch: [],
        recipients: null,
        subject: "",
        body: "",
        attachments: [],
        isActionRequired: "0"
      };
    },
    showMessageForm(type) {
      this.needMessageForm = true;

      this.replyType = type;
      this.newMessage.subject = this.message.subject;
      this.newMessage.body = ""; //this.message.body;

      if (type === "reply") {
        if (
          this.recipients.filter(el => el.id == this.message.sender.id)
            .length == 0
        )
          this.recipients.push({
            id: this.message.sender.id,
            name: this.message.sender.fullName
          });

        this.newMessage.sendTo.push(this.message.sender.id);
      }
    },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    sendMessage() {
      this.newMessage.isNew = true;
      this.newMessage.parentId = this.message.id;
      this.newMessage.isActionRequired =
        this.newMessage.isActionRequired == "1";

      this.newMessage.recipients = this.newMessage.sendTo.map(el => {
        return { id: el };
      });

      this.newMessage.attachments = this.attachmentsObj
        .filter(i => i.url && i.url.length > 0)
        .map(i => {
          return { id: i.fileId, fileUrl: i.url };
        });
      this.newMessage.attachments = this.newMessage.attachments.filter(
        (item, index) => this.newMessage.attachments.indexOf(item) === index
      ); //remove duplicates

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.requestProcessing = true;
      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_MESSAGES}`,
          data: this.newMessage
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Message has been sent";

            self.$router.push({
              name: "CustomerMessages",
              params: { customerId: this.customerId }
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });

          self.requestProcessing = false;
        });

      // this.saveCustomer();
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    },
    async getMessage() {
      // let comunityItems = dataModels.communityDetails;
      let self = this;
      this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_MESSAGES}`,
          id: this.messageId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.message = response.data;

            self.markMessageAsOpened();
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    },

    async markMessageAsOpened() {
      let self = this;
      let url = `${API_MESSAGES}/${self.messageId}/opened`;
      this.$store.dispatch(POST_DATA, {
        listName: url
      });
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();

            this.recipients.push({
              id: this.communityInfo.propertyManager.id,
              name: this.communityInfo.propertyManager.fullName
            });
            this.recipients.push({
              id: this.communityInfo.accountManager.id,
              name: this.communityInfo.accountManager.fullName
            });
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    },
    setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Messages",
          route: "/customers/" + this.customerId + "/messages"
        },
        { title: "View Message" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Messages",
          route: "/customers/" + this.customerId + "/messages"
        },
        { title: "View Message" }
      ]);
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    },
    sendToErrors() {
      const errors = [];
      if (!this.$v) return errors;
      if (!this.$v.newMessage.recipients.$dirty) return errors;
      !this.$v.newMessage.recipients.required && errors.push("Required.");
      !this.$v.newMessage.recipients.minLength && errors.push("Required.");
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v) return errors;
      if (!this.$v.newMessage.subject.$dirty) return errors;
      !this.$v.newMessage.subject.required && errors.push("Required.");
      return errors;
    },
    bodyErrors() {
      const errors = [];
      if (!this.$v) return errors;
      if (!this.$v.newMessage.body.$dirty) return errors;
      !this.$v.newMessage.body.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>
